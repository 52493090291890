import React, { useState }  from 'react';
import {Button,Tooltip} from 'reactstrap';
import {toggleMinimized} from '../../../Actions/wkshpActions';
import { connect } from 'react-redux'
import './removeAccountButton.scss';

function RemoveAccountButton(props){
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [buttonText, setButtonText] = useState("-")

    const toggle =() =>{
        setTooltipOpen((tooltipOpen)=>(!tooltipOpen));
    }

    const toggleButton =()=>{
        buttonText === "-" ? setButtonText("Close") : setButtonText("-")
    }

    const onButtonClick = () =>{
        props.onClick();
        let minimized = buttonText === "-" ? true : false
        props.accounts.map(account =>{
            parseFloat(props.currentTransaction.number)>5 ? 
            props.setMinimized(account.id, true) : 
            (account.visible? props.setMinimized(account.id, minimized):
                props.setMinimized(account.id, true) )
        }
        )

        toggleButton();
    }
    
    return(
        <div className = 'btn-remove-account'>
            <Button size = "sm" onClick = {onButtonClick} id="deleteButton" className = "btn-light">{buttonText}</Button>
            <Tooltip placement="right" isOpen={tooltipOpen} target="deleteButton" toggle={toggle}>
          Remove Account
        </Tooltip>
        </div>
    )
}

const mapStateToProps = (state, props)=>
    ({
        onClick: props.onClick,
        accounts: props.accounts,
        currentTransaction : state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0]
	})
const mapDispatchToProps = dispatch => {
	return {
        setMinimized(acccountId, minimized){
            dispatch(
                toggleMinimized(acccountId, minimized)
            )
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAccountButton)