import React from 'react';
import { connect } from 'react-redux'
import '../sticker.scss';

function DisabledSticker(props){
    const color = props.color;
    const text = parseInt(props.text).toLocaleString();
    const currency  = props.currency;

    return(
        <div className = {`card sticker disabled ${color}`}>
            <div className = 'card-body'>
                {currency + text}
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => 
      ({
		color: props.color,
		text: props.text,
		currency : state.currency
	})

export default connect(mapStateToProps)(DisabledSticker);