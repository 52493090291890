import React, {Component} from 'react';
import '../../App.scss';
import CurrencyModal from '../CurrencyModal'
import SummaryPage from '../SummaryPage'
import PrintableSummary from '../SummaryPage/PrintableSummary'
import OuterBoard from '../Board/OuterBoard';
import {AssetsLayout, LiabilitiesLayout, ExpensesLayout, IncomeLayout} from '../Board/AccountBoards/AccountBoards';
import Sidebar from '../SideBar';
import NavigationButton from '../NavigationButton';
import {DragDropContext} from 'react-beautiful-dnd';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {addStickerfromSidebar, addStickerfromAccount, removeSidebarSticker, removeSticker, addTotal, subtractTotal,returnSidebarSticker, rerenderComponent, setCompanyName} from '../../Actions/wkshpActions'
import { connect } from 'react-redux'

class Workshop extends Component {
  
  state = {
    currentTransaction : this.props.transactions.filter((transaction)=>transaction.currentTransaction===true)[0],
    currency: this.props.currency,
    companyName: this.props.companyName
  }

  updateTransaction=()=>{
    this.setState({currentTransaction:this.props.transactions.filter((transaction)=>transaction.currentTransaction===true)[0]})
  }

  onCompanyNameChange = (e) =>{
    this.props.setCompanyName(e.target.value)
  }

  onDragEnd = result =>{
    const{destination, source, draggableId} = result;
    console.log(`stickerid ${draggableId}`)
    if(!destination) {
      return;
    }

    else if (destination.droppableId === source.droppableId){
      return;
    }
    else if (source.droppableId === "SIDEBAR"){
      this.props.addStickerfromSidebar(destination.droppableId, this.state.currentTransaction.id, draggableId)
      this.props.removeSidebarSticker(draggableId, this.state.currentTransaction.id)
      this.props.addTotal(destination.droppableId, draggableId)
    }
    else if (destination.droppableId === "SIDEBAR" && source.droppableId!=="SIDEBAR"){
      if (this.state.currentTransaction.number === draggableId.split("_")[1]){
        this.props.subtractTotal(source.droppableId, draggableId)
        this.props.returnSidebarSticker(this.state.currentTransaction.id, draggableId.split("_")[0],source.droppableId, draggableId)
        this.props.rerenderComponent(Date.now())
      }
    }
    else{
      this.props.subtractTotal(source.droppableId, draggableId)
      this.props.addStickerfromAccount(source.droppableId, destination.droppableId, draggableId)
      this.props.removeSticker(source.droppableId, draggableId)
      this.props.addTotal(destination.droppableId, draggableId)
      this.props.rerenderComponent(Date.now())
    }
    return
    
  }
  render(){
    return (
      <div>
      {!this.props.summaryView &&
      <DragDropContext onDragEnd={this.onDragEnd}>
        <DndProvider backend={HTML5Backend}>
          <div className="container-fluid d-flex App">
            <div className= "row col">
              <Sidebar updateTransaction={this.updateTransaction}/>
              <div className = "row col-10 rct-content">
              {this.state.currency === "" &&
              <CurrencyModal/>}
                <div className = "row col-12 header">
                  <NavigationButton name = 'Previous' onClick = {this.updateTransaction}/>
                  <input className = ' company-title' type = "text" onChange= {this.onCompanyNameChange}
                  defaultValue = {this.state.companyName}/>
                  <NavigationButton name = 'Next' onClick = {this.updateTransaction}/>
                </div>
                <div className = "row col-12 boards">
                  <OuterBoard color='blue'>
                    <AssetsLayout />
                    <ExpensesLayout visible={this.state.currentTransaction.addedBoards?'':'hidden'}/>
                  </OuterBoard>
                  <OuterBoard color='red'>
                    <LiabilitiesLayout/>
                    <IncomeLayout visible={this.state.currentTransaction.addedBoards?'':'hidden'}/>
                  </OuterBoard>
                </div>
              </div>
            </div>
          </div>
        </DndProvider>
      </DragDropContext>
          }
      {this.props.summaryView &&
        <div className = "row col summary-view">
          <div className = "row col-12 header">
          <NavigationButton name = 'Last' onClick = {this.updateTransaction}/>
          <PrintableSummary/>
          </div>
          <div className = "row col-10 summary">             
           <SummaryPage/>
         </div>
         </div>
       }
       </div>
    );
  }
}

const mapStateToProps = (state)=>
    ({
        transactions: state.transactions,
        currency: state.currency,
        companyName: state.companyName,
        summaryView: state.summaryView
	})
const mapDispatchToProps = dispatch => {
	return {
      addStickerfromSidebar(accountId, transactionId, stickerId){
            dispatch(
              addStickerfromSidebar(accountId, transactionId, stickerId)
            )
        },
      removeSidebarSticker(stickerId, transactionId){
          dispatch(
            removeSidebarSticker(stickerId, transactionId)
          )
      },
      addTotal(accountId, stickerId){
        dispatch(
          addTotal(accountId, stickerId)
        )
      },
      subtractTotal(accountId, stickerId){
        dispatch(
          subtractTotal(accountId, stickerId)
        )
      },
      rerenderComponent(timestamp){
        dispatch(
          rerenderComponent(timestamp)
        )
      },
      addStickerfromAccount(sourceAccountId, destAccountId, stickerId){
        dispatch(
          addStickerfromAccount(sourceAccountId, destAccountId, stickerId)
        )
      },
      removeSticker(accountId, stickerId){
        dispatch(
          removeSticker(accountId, stickerId)
        )
      },
      setCompanyName(name){
        dispatch(
          setCompanyName(name)
        )
      },
      returnSidebarSticker(transactionId, stickerColor, accountId, stickerId){
        dispatch(
          returnSidebarSticker(transactionId, stickerColor, accountId, stickerId)
        )
      }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Workshop)
