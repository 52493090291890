import React,{useState} from 'react'
import {Button} from 'reactstrap'
import ResetConfirmModal from './ResetConfirmModal'
import {resetAccounts, rerenderComponent} from '../../Actions/wkshpActions'
import { connect } from 'react-redux'
import './resetButton.scss'

function ResetButton(props){
    const [modalOpen, setModalOpen] = useState(false)
    const accounts = props.accounts

    const resetAccounts = ()=>{
        props.resetAccounts()
        props.rerenderComponent(Date.now())
        accounts.map(account =>{
            document.getElementById(account.id).innerText='NAME'
        })
        setModalOpen(modalOpen=>false)
        props.updateTransaction()
    }

    const setModalClosed = ()=>{
        setModalOpen(modalOpen=>false)
    }


    return(
        <div>
            <div className = {`btn-reset`}>
                <Button size = "sm" onClick = {()=>setModalOpen(true)}  className = "btn btn-light">Reset</Button>
            </div>
            <div>
                {modalOpen &&
                <ResetConfirmModal open={modalOpen} close = {setModalClosed} resetAccounts = {resetAccounts}/>
                }
            </div>
        </div>
    )

}

const mapStateToProps = (state, props)=>
    ({
        accounts: state.accounts,
        updateTransaction: props.updateTransaction
	})
const mapDispatchToProps = dispatch => {
	return {
        resetAccounts(){
            dispatch(
                resetAccounts()
            )
        },
        rerenderComponent(timestamp){
            dispatch(
                rerenderComponent(timestamp)
            )
        },
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetButton)