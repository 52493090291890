import React, { useState} from 'react';
import '../board.scss';
import Account from '../../Account';
import AccountLayoutTitle from './AccountLayoutTitle';

export default function ExpensesAccountLayout(props){

    const [removeAccounts, setRemoveAccounts] = useState(false);

    const visible = props.visible
    const expensesAccounts = props.accounts.slice(6,12)
    const invisibleAccounts = expensesAccounts.filter(account=> !account.visible)

    let incomeTotal = 0;
    let expensesTotal = 0;

    props.accounts.slice(19).map(account=>{
        incomeTotal = incomeTotal+ account.total
    })

    props.accounts.slice(6,12).map(account=>{
        expensesTotal = expensesTotal+ account.total
    })

    let loss  = (expensesTotal - incomeTotal) > 0;


    const addAccount = () =>{
        if (invisibleAccounts.length>0){
            props.toggleAccount(invisibleAccounts[0].id, "true")
        }
    }

    const allowRemoveAccounts = ()=>{
        setRemoveAccounts(removeAccounts => !removeAccounts)
        expensesAccounts.map(account => 
            props.setMinimized(account.id, true)
        )
    }

    const color = 'blue';
    return(
        <div className = {`account-board expenses ${visible} ${color} ${(loss?" extend" : "")}`}>
            <AccountLayoutTitle title = "Expenses" addButton = {addAccount} removeButton = {allowRemoveAccounts} accounts={expensesAccounts}/>
            <div className = "row account-place ">
                <div className =  "col-4 account-col">
                    <div className= 'row'>
                        <Account id = "6" removable = {removeAccounts}/>
                        </div>
                        <div className= 'row'>
                        <Account id = "9" removable = {removeAccounts}/>
                    </div>
                </div>
                <div className =  "col-4 account-col">
                    <div className= 'row'>
                        <Account id = "7" removable = {removeAccounts}/>
                    </div>
                    <div className= 'row'>
                        <Account id = "10" removable = {removeAccounts}/>
                    </div>
                </div>
                <div className =  "col-4 account-col">
                    <div className= 'row'>
                        <Account id = "8" removable = {removeAccounts}/>
                    </div>
                    <div className= 'row'>
                        <Account id = "11" removable = {removeAccounts}/>
                    </div>
                </div>
            </div>
            {loss &&
                <div className = 'total-calculation'>
                    <div className = "row col-12">
                        <hr className="total-break"/>
                        <label className='totals-title'>Loss</label>
                        {/* <label className = 'totals-amt'>{loss}</label> */}
                    </div>
                </div>
                }
        </div>
    )
}