import React, {Component} from 'react';
import '../board.scss';

class OuterBoard extends Component{
    
	render() {
		const {color} = this.props;
		return (
			<div className ="card border-0 col-6">
				<div className = {`board ${color}`}>
					{this.props.children}
				</div>
			</div>
		)
	}
}

export default OuterBoard;