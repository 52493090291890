import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import Workshop from "./Components/Routes/Workshop";
import Login from "./Components/Routes/Login";
import SetPassword from "./Components/Routes/SetPassword"

function App(props) {
  const { isAuthenticated, isVerifying } = props;
  return (
    <BrowserRouter>
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        component={Workshop}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <Route path="/login" component={Login} />
      <Route path="/set-password" component= {SetPassword}/>
    </Switch>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(App);