import {database, auth, myFirebase} from '../firebase'

export const authConstants = {
    LOGIN_REQUEST : "LOGIN_REQUEST",
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    LOGIN_FAILURE : "LOGIN_FAILURE",
    LOGOUT_REQUEST : "LOGOUT_REQUEST",
    LOGOUT_SUCCESS : "LOGOUT_SUCCESS",
    LOGOUT_FAILURE : "LOGOUT_FAILURE",
    VERIFY_REQUEST : "VERIFY_REQUEST",
    VERIFY_SUCCESS : "VERIFY_SUCCESS"
}

export const requestLogin = () => {
    return {
      type: authConstants.LOGIN_REQUEST
    };
  };

export const receiveLogin = user => {
    return {
        type: authConstants.LOGIN_SUCCESS,
        user
    };
};

export const loginError = () => {
    return {
        type: authConstants.LOGIN_FAILURE
    };
};

export const requestLogout = () => {
    return {
      type: authConstants.LOGOUT_REQUEST
    };
  };

export const receiveLogout = user => {
    return {
        type: authConstants.LOGOUT_SUCCESS,
        user
    };
};

export const logoutError = () => {
    return {
        type: authConstants.LOGOUT_FAILURE
    };
};

export const verifyRequest = () => {
    return {
        type: authConstants.VERIFY_REQUEST
    };
};

export const verifySuccess = () => {
    return {
        type: authConstants.VERIFY_SUCCESS
    };
};

export const loginUser = (email, password) => dispatch => {
    console.log(`email ${email}`)
    dispatch(requestLogin());
    const userRef = database.collection('users')
    let userId = ''
    userRef.where('email', '==',email).get()
    .then(snapshot=>{
        snapshot.docs.forEach(doc=>{
            userId = doc.id
        })
    })
    .then(response =>{
        userRef.doc(userId).get()
        .then(doc=>{
            if (doc.data().role === 'independentTrainer' || doc.data().role === 'ameTrainer' ){
                console.log('signing in Trainer')
                myFirebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(user => {
                    dispatch(receiveLogin(doc.data()));
                })
                .catch(error => {
                    console.log(error)
                    dispatch(loginError());
                });

            }
            else{
                if(password === doc.data().password) {
                    dispatch(receiveLogin(doc.data()))
                    userRef.doc(userId).update({
                        numLogins: parseInt(doc.data().numLogins)+1,
                        lastLoginDate: new Date
                    })
                    if (!doc.data().activationDate){
                        userRef.doc(userId).update({
                            activationDate: new Date
                        })
                    }
                }
                else{
                    console.log('incorrect password')
                    dispatch(loginError())
                }
            }
        })
    })
}


    // myFirebase
    //   .auth()
    //   .signInWithEmailAndPassword(email, password)
    //   .then(user => {
    //     dispatch(receiveLogin(user));
    //   })
    //   .catch(error => {
    //     //Do something with the error if you want!
    //     dispatch(loginError());
    //   });


export const logoutUser = () => dispatch => {
    console.log('sending logout request')
    dispatch(requestLogout());
    dispatch(receiveLogout());
};

export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    myFirebase.auth().onAuthStateChanged(user => {
      if (user !== null) {
        dispatch(receiveLogin(user));
      }
      dispatch(verifySuccess());
    });
};