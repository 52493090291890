import React, { useState }  from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import FlagIcon from '../FlagIcon'
import {connect} from 'react-redux';
import {setCurrency} from '../../Actions/wkshpActions';
import './currencyModal.scss'

function CurrencyModal(props){

    const [open, setOpen] = useState(true)
    const onClick = (symbol)=>{
        props.setCurrency(symbol)
        setOpen(!open)
    }

    return(
        <div>
            <Modal className = "currency-modal" isOpen={open} >
                <ModalHeader>Choose Currency</ModalHeader>
                <ModalBody className = "currency-modal-body">
                    <div className = "row col-12 currency-row">
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("$")}>
                            <FlagIcon code = "us"/>United States (USD$)
                        </Button>
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("£")}>
                            <FlagIcon code = "gb"/>England (£)
                        </Button>
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("€")}>
                            <FlagIcon code = "eu"/>Europe (€)
                        </Button>
                    </div>
                    <div className = "row col-12 currency-row">
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("$")}>
                            <FlagIcon code = "au"/>Australia (AUD$)
                        </Button>
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("$")}>
                            <FlagIcon code = "ca"/>Canada (CAD$)
                        </Button>
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("R")}>
                            <FlagIcon code = "za"/>South Africa (R)
                        </Button>
                    </div>
                    <div className = "row col-12 currency-row">
                        <Button className = "btn-light btn-currency" onClick={()=>onClick("₪")}>
                            <FlagIcon code = "il"/>Israel (₪)
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
      </div>
    )
}

const mapStateToProps = state => 
	({
		currency: state.currency
	})

const mapDispatchToProps = dispatch => {
	return {
		setCurrency(currency) {
			dispatch(
				setCurrency(currency)
			)
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyModal)