import React, { useState} from 'react';
import '../board.scss';
import Account from '../../Account';
import AccountLayoutTitle from './AccountLayoutTitle'

export default function AssetsAccountLayout(props){

    const [removeAccounts, setRemoveAccounts] = useState(false)

    const assetAccounts =  props.accounts.slice(0,6)
    const invisibleAccounts = assetAccounts.filter(account=> !account.visible)

    const addAccount = () =>{
        if (invisibleAccounts.length>0){
            props.toggleAccount(invisibleAccounts[0].id, true)
        }
    }

    const allowRemoveAccounts = ()=>{
        setRemoveAccounts(removeAccounts => !removeAccounts)
        assetAccounts.map(account => 
            props.setMinimized(account.id, true)
        )
    }

    const halfView = parseFloat(props.transaction.number) > 5;
    const color = 'blue';

    return(
        <div className = {`account-board assets ${color} ${(halfView?" halfView":"")}`}>
            <AccountLayoutTitle title = "Assets" addButton = {addAccount} removeButton = {allowRemoveAccounts} accounts={assetAccounts}/>
            <div className = "row account-place ">
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                        <Account id = "0" removable = {removeAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                    <Account id = "1" removable = {removeAccounts}/>
                    </div>
                    <div className= 'row'>
                    <Account id = "4" removable = {removeAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                        <Account id = "2" removable = {removeAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                <div className= 'row'>
                    <Account id = "3" removable = {removeAccounts}/>
                    </div>
                    <div className= 'row'>
                    <Account id = "5" removable = {removeAccounts}/>
                    </div>
                </div>
            </div>
        </div>
    )
}