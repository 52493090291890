import React, {Component} from 'react'
import {Table} from 'reactstrap'
import './summaryPage.scss'
import {connect} from 'react-redux'

class SummaryPage extends React.PureComponent{

    constructor(props) {
        super(props)
        this.debitAccountNames= [];
        this.creditAccountNames= [];
    }

    state = {
        transactions : this.props.transactions,
        accounts : this.props.accounts,
        firstName : this.props.userName.split(" ")[0]
    }

    getDebitAccountNames=()=>{
        this.state.transactions.map((transaction,i)=>{
            let account =this.state.accounts.filter(account=> account.id===transaction.stickers[0].location)[0]
            this.debitAccountNames[i] = account ? [account.name, account.color] : ['', 'gray']
        })
    }

    getCreditAccountName=()=>{
        this.state.transactions.map((transaction,i)=>{
            let account =this.state.accounts.filter(account=> account.id===transaction.stickers[1].location)[0]
            this.creditAccountNames[i] = account ? [account.name, account.color] : ['', 'gray']
        })
    }

    displayTransactions = ()=>{
        this.getDebitAccountNames()
        this.getCreditAccountName()
        
        const transactionRows = this.state.transactions.map((transaction,i) =>
           <tbody>
               <tr>
                <th className = 'transaction-num'>#{transaction.number}</th>
                    <th className = 'date'>{transaction.date}</th> 
                    <td className = {this.debitAccountNames[i][1]}>{this.debitAccountNames[i][0]}</td>
                    <td className = 'blue'>{this.props.currency}{transaction.stickers[0].amount}</td>
                    <td></td>   
                </tr>
                <tr>
                    <th colSpan= '2'></th>
                    <td className = {this.creditAccountNames[i][1]}>{this.creditAccountNames[i][0]}</td>
                    <td></td>
                    <td className = 'red'>{this.props.currency}{transaction.stickers[1].amount}</td>   
                </tr>
                <tr>
                    <td colSpan ='5' >{transaction.summary}</td>
                </tr>
                <tr>
                    <td colSpan ='5' className = 'note'>{this.state.firstName}'s Notes: {transaction.note}</td>
                </tr>
            </tbody> 
        
        )
        return transactionRows
    }

    render(){
        return(
            <div className = 'summary-table'>
                <Table bordered striped style= {{border: '2px black'}}>
                    <thead>
                        <tr>
                            <th colSpan = '5' className = 'table-title'>{this.props.companyName}</th>
                        </tr>
                        <tr>
                        <th>Transaction</th>
                            <th>Date</th>
                            <th>Account</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    {this.displayTransactions()}
                </Table>
            </div>
        )
    }

}
const mapStateToProps = state =>
    ({
        transactions: state.transactions,
        companyName: state.companyName,
        accounts: state.accounts,
        currency: state.currency,
        userName : state.auth.user.name
	})


export default connect(mapStateToProps)(SummaryPage)