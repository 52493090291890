const constants = {
    ADD_STICKER : "ADD_STICKER",
    REMOVE_STICKER: "REMOVE_STICKER",
    REMOVE_SIDEBAR_STICKER: "REMOVE_SIDEBAR_STICKER",
    TOGGLE_VISIBLE: "TOGGLE_VISIBLE",
    ADD_TOTAL: "ADD_TOTAL",
    SUBTRACT_TOTAL: "SUBTRACT_TOTAL",
    CHANGE_TRANSACTION:"CHANGE_TRANSACTION",
    TOGGLE_MINIMIZED: "TOGGLE_MINIMIZED",
    SET_CURRENCY:"SET_CURRENCY",
    TOGGLE_HIGHTLIGHTED:"TOGGLE_HIGHTLIGHTED",
    CLEAR_ACCOUNT_STICKERS:"CLEAR_ACCOUNT_STICKERS",
    RESTORE_SIDEBAR_STICKERS:"RESTORE_SIDEBAR_STICKERS",
    RETURN_SIDEBAR_STICKER:"RETURN_SIDEBAR_STICKER",
    TRIGGER_RERENDER:"TRIGGER_RERENDER",
    SET_COMPANY_NAME:"SET_COMPANY_NAME",
    CHANGE_ACCOUNT_NAME:"CHANGE_ACCOUNT_NAME",
    RESET_ACCOUNTS:"RESET_ACCOUNTS",
    LOGIN_REQUEST : "LOGIN_REQUEST",
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    LOGIN_FAILURE : "LOGIN_FAILURE",
    LOGOUT_REQUEST : "LOGOUT_REQUEST",
    LOGOUT_SUCCESS :"LOGOUT_SUCCESS",
    LOGOUT_FAILURE : "LOGOUT_FAILURE",
    VERIFY_REQUEST : "VERIFY_REQUEST",
    VERIFY_SUCCESS : "VERIFY_SUCCESS",
    SAVE_NOTE: "SAVE_NOTE",
    SET_SUMMARY_VIEW: "SET_SUMMARY_VIEW"
}

export default constants;