import React from 'react';
import '../board.scss';
import AccountButton from '../../Account/AccountButton';
import RemoveAccountButton from '../../Account/RemoveAccountButton'

export default function AccountLayoutTitle(props){
    return(
        <div className = "row col-12 title">
            <AccountButton onClick={props.addButton}/>
            <div className = "card-body board-title">
                {props.title}
            </div>
            <RemoveAccountButton onClick= {props.removeButton} accounts ={props.accounts} />
        </div>
    )
}