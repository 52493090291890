import React from 'react'
import {Button} from 'reactstrap'
import './logoutButton.scss'
import {connect} from 'react-redux'
import {logoutUser} from '../../Actions/authActions'

function LogoutButton(props){

    const onClick = ()=>{
        console.log('clicked')
        props.logoutUser()
    }


    return(
        <div className = {`btn-logout`}>
            <Button size = "sm" onClick = {onClick}  className = "btn btn-light">Logout</Button>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
	return {
		logoutUser() {
			dispatch(
				logoutUser()
            )
            }
        }
    }

export default connect(null, mapDispatchToProps)(LogoutButton)