/**
 */
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

// Initialize Firebase 
const config = {
   apiKey: "AIzaSyBk1-LotqjqLR37ejCWoSpX-ndCrZBsN7o",
    authDomain: "accounting-me.firebaseapp.com",
    databaseURL: "https://accounting-me.firebaseio.com",
    projectId: "accounting-me",
    storageBucket: "accounting-me.appspot.com",
    messagingSenderId: "1014616232547",
    appId: "1:1014616232547:web:093669c16808a48b64de56",
    measurementId: "G-0RM0WW2VSW"
};

const myFirebase = firebase.initializeApp(config);

const auth = firebase.auth();
const database = firebase.firestore();

export {
    myFirebase,
   auth,
   database
};
