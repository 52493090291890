import React, {Component} from 'react'
import Summary from './index'
import './summaryPage.scss'

export class ComponentToPrint extends React.PureComponent {
    render() {
      return (
          <div className = "printable">
                <Summary/>
        </div>
      );
    }
  }