import React, {useRef} from 'react'
import {useReactToPrint} from 'react-to-print';
import {ComponentToPrint} from './ComponentToPrint'

const Printable = () =>{

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
 
  console.log(componentRef.current)
  return (
    <div className = 'print-button'>
      <div style={{ display: "none"}}>
        <ComponentToPrint ref ={componentRef} />
      </div>
      <button className = 'btn btn-light' onClick={(handlePrint)}>Print</button>
    </div>
  );
}
export default Printable;