import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import { Popover, Fade } from '@material-ui/core';
import {toggleMinimized, toggleHighlighted} from '../../Actions/wkshpActions';
import { connect } from 'react-redux'
import './sticker.scss';

function Sticker (props){
    const [popoverOpen, setPopoverOpen] = useState(false)
    const color = props.color;
    const id = props.id
    const text = parseInt(props.text).toLocaleString();
    const currency  = props.currency;
    const highlight = props.highlight ? "highlight" : "";
    const location = props.location;
    const transaction = props.transactions.filter(transaction=> transaction.number=== props.id.split("_")[1])[0]

    const stickerPairLocation = color === 'red'? transaction.stickers[0].location : transaction.stickers[1].location
    const stickerPairId = color === 'red' ?`blue_${id.split('_')[1]}` : `red_${id.split('_')[1]}`

    const onStickerClick = ()=>{
        if(location!=='sidebar' && stickerPairLocation!=='sidebar'){
            props.onStickerClick(location, id, stickerPairLocation, stickerPairId)
            setPopoverOpen(true)
            setTimeout(()=>{
                setPopoverOpen(false)
            },5000)
        }
    }

    const onPopoverClick = ()=>{
        setPopoverOpen(false)
    }
   

    return(
        <div>
            <Fade in={popoverOpen} timeout = {5000}>
                <div>
                <Popover className="popover"
                    anchorReference="anchorPosition"
                    anchorPosition={{ top:0, left: 0 }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open = {popoverOpen}
                    height = '100px'
                    width = '100px'
                    onClick= {onPopoverClick}
                    style = {{top: '200px'}}
                    >
                        <div className = 'popover-content'>
                            <div className = 'popover-title'>{`Transaction #${transaction.number}`}</div>
                            <div className = 'popover-date'>{transaction.date}</div>
                            <div>{transaction.summary}</div>
                        </div>
                    
                </Popover>
                </div>
        </Fade>
        <Draggable
                key={id}
                draggableId ={id}
                index = {props.index}>
                {(provided, snapshot) => (
            <div className = {`card sticker ${color} ${highlight} ${snapshot.isDragging?'dragging':''}`}  
            onClick = {onStickerClick} ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {/* {provided.placeholder} */}
                <div className = 'card-body'>
                    {currency + text}
                </div>
            </div>
        )}
        </Draggable>
        </div>
    )
}

const mapStateToProps = (state, props) => 
      ({
		color: props.color,
        id: props.id,
        index: props.index,
		text: props.text,
		currency : state.currency,
		highlight: props.highlight,
		transactions: state.transactions,
		rerenderTrigger: state.rerenderTrigger
	})

const mapDispatchToProps = dispatch => {
	return {
		onStickerClick(accountId, stickerId, pairAccountId, pairStickerId) {
			dispatch(
				toggleMinimized(accountId, false)
			)
			dispatch(
				toggleMinimized(pairAccountId, false)
			)
			dispatch(
				toggleHighlighted(accountId, stickerId, true)
			)
			setTimeout(()=>{
				dispatch(
					toggleHighlighted(accountId, stickerId, false)
				)
			},5000)
			dispatch(
				toggleHighlighted(pairAccountId, pairStickerId, true)
			)
			setTimeout(()=>{
				dispatch(
					toggleHighlighted(pairAccountId, pairStickerId, false)
				)
			},5000)
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sticker);