import React, { useState }  from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'


export default function ResetConfirmModal(props){

    const [open, setOpen] = useState(props.open)
    const closeModal = ()=>{
        props.close()
    }

    console.log(open)
    return(
        <div>
            <Modal className = "currency-modal" isOpen={open} >
                <ModalHeader>Reset Accounts Confirmation</ModalHeader>
                <ModalBody className = "currency-modal-body">
                    Would you like to reset your accounts and transactions?
                </ModalBody>
                <ModalFooter>
                    <Button className = 'btn btn-primary' onClick={closeModal}>Cancel</Button>
                    <Button className = 'btn btn-primary' onClick = {props.resetAccounts}>Confirm Reset</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}