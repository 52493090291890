import React, { Component } from "react";
import { connect } from "react-redux";
import {Redirect } from "react-router-dom";
import './login.scss'
import {database} from '../../firebase'
import { loginUser} from "../../Actions/authActions";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from '@material-ui/core/CircularProgress';
import { TramRounded } from "@material-ui/icons";

class SetPassword extends Component {
    state = {
        password1: '',
        password2 : '',
        passwordSet : false,
        error : '',
        email : this.props.location.state.email
    }

    handlePassword1Change = ({ target }) => {
        this.setState({ password1: target.value });
      };

    handlePassword2Change = ({ target }) => {
    this.setState({ password2: target.value });
    };
  

    handlePasswordSet = ()=>{
        const userRef = database.collection('users')
        const {password1, password2, email}= this.state
        console.log(email)
        let userId = ''
        if(password1 === password2){
            userRef.where('email', '==',email).get()
            .then(snapshot=>{
                snapshot.docs.forEach(doc=>{
                    userId = doc.id
                })
            })
            .then(response =>{
              userRef.doc(userId).update({password: password1})
              this.props.loginUser(email, password1);
          })
          .then(response =>{
            setTimeout(()=>{this.setState({passwordSet:true, loading:false})},1000)
          })
        }
        else{
            this.setState({error: 'Passwords do not match'})
        }
    }

    render() {
        const {passwordSet, error} = this.state
        if(passwordSet){
            return (
                    <Redirect to="/"/>
            )
        }
         else {
          return (
              <div className = 'row login-page justify-content-center'>
              <Paper className='paper'>
                <div className = 'logo'>
                    <img src = {process.env.PUBLIC_URL+'/ame-logo.png'} alt = 'ame-logo'/>
                </div>
                <div className="title">
                  <h3 className="font-weight-bold">Set Password</h3>
                  <p className="mb-0">Accounting Made Easy</p>
              </div>
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Enter Password"
                type="password"
                id="password1"
                  onChange={this.handlePassword1Change}
                />
              
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Enter Password Again"
                  type="password"
                  id="password2"
                  onChange={this.handlePassword2Change}
                />
              
                {error && (
                  <Typography component="p" className='error-text'>
                    {error}
                  </Typography>
                )}
              
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className='submit'
                  onClick={this.handlePasswordSet}
                >
                  Set Password
                </Button>
              
              </Paper>
            </div>
          );
        }
      }
}

function mapStateToProps(state) {
    return {
      isLoggingIn: state.auth.isLoggingIn,
      loginError: state.auth.loginError,
      isAuthenticated: state.auth.isAuthenticated
    };
  }

function mapDispatchToProps (dispatch){
  return {
        loginUser(email, password) {
            dispatch(
                loginUser(email, password)
            )
            }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);