import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser, loginError } from "../../Actions/authActions";
import './login.scss'
import {database} from '../../firebase'
import {NotificationManager} from 'react-notifications'

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Label } from "reactstrap";


class Login extends Component {
    state = { 
        email: "", 
        password: "",
        emailEntry: true,
        passwordEntry: false,
        setPassword: false,
        error:''
     };
  
    handleEmailChange = ({ target }) => {
      this.setState({ email: target.value });
    };
  
    handlePasswordChange = ({ target }) => {
      this.setState({ password: target.value });
    };

    handleEmail = () =>{
        const {email}= this.state;
        let userRef = database.collection('users')
        userRef.where('email','==',email).get()
        .then(snapshot =>{
            !snapshot.empty?
            snapshot.forEach(doc=>{
              if (doc.data().role === 'independentTrainer' || doc.data().role === 'ameTrainer' ){
                this.setState({passwordEntry: true})
              }
              else{
                let licenseTime = 30
                if(doc.data().activationDate){
                  let activationDate = new Date(parseInt(doc.data().activationDate.seconds)*1000)
                  licenseTime = 30 - Math.floor((Date.now()-activationDate)/86400000)
                }
                if (licenseTime > 0){
                  console.log(doc.data().email)
                  doc.data().password?
                  this.setState({passwordEntry: true}):
                  this.setState({setPassword: true})
                }
                else{
                  this.setState({error: 'License is expired. Contact AME trainer'})
                }
              }
            }):
            this.setState({error: 'User does not exist'})
        })
    }  

    handlePassword = () => {
      const { loginUser, loginError } = this.props;
      const { email, password } = this.state;
      loginUser(email, password);
      if (loginError){
        this.setState({error:'Incorrect Password'})    
      }  
    };
  
    render() {
      const {isAuthenticated } = this.props;
      const {emailEntry, passwordEntry, setPassword, email, error} = this.state;
      if (isAuthenticated) {
        return <Redirect to="/" />;
      }
      else if(setPassword){
          return <Redirect to={{
            pathname: '/set-password',
            state: { email: email }
        }} />;
      }
       else {
        return (
            <div className = 'row login-page justify-content-center'>
            <Paper className='paper'>
              <div className = 'logo'>
                  <img src = {process.env.PUBLIC_URL+'/ame-logo.png'} alt = 'ame-logo'/>
              </div>
              <div className="title">
                <h3 className="font-weight-bold">Sign into your course</h3>
                <p className="mb-0">Finance and Accounting Made Easy</p>
            </div>
            {emailEntry &&
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={this.handleEmailChange}
              />
            }
            {passwordEntry &&
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={this.handlePasswordChange}
              />
            }
              {loginError && (
                <Typography component="p" className='error-text'>
                  {error}
                </Typography>
              )}
              {emailEntry &&
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className='submit'
                onClick={!passwordEntry?this.handleEmail:this.handlePassword}
              >
                {!passwordEntry?'Next':'Sign In'}
              </Button>
            }
            {/* {passwordEntry &&
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className='submit'
                onClick={this.handlePassword}
              >
                Sign In
              </Button>
            } */}
            </Paper>
          </div>
        );
      }
    }
  }
  
  function mapStateToProps(state) {
    return {
      isLoggingIn: state.auth.isLoggingIn,
      loginError: state.auth.loginError,
      isAuthenticated: state.auth.isAuthenticated
    };
  }

  function mapDispatchToProps (dispatch){
	return {
        loginUser(email, password) {
            dispatch(
                loginUser(email, password)
            )
            }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


  