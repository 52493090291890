import React, {useState} from 'react';
import '../board.scss';
import Account from '../../Account';
import AccountLayoutTitle from './AccountLayoutTitle';

export default function LiabilitiesAccountLayout(props){
    const [removeLiabilitiesAccounts, setRemoveLiabilitiesAccounts] = useState(false)
    const [removeEquityAccounts, setRemoveEquityAccounts] = useState(false)

    const liabilitiesAccounts = props.accounts.slice(12,16)
    const liabilitiesInvisibleAccounts = liabilitiesAccounts.filter(account=> !account.visible)

    const equityAccounts = props.accounts.slice(16,19)
    const equityInvisibleAccounts = equityAccounts.filter(account=> !account.visible)

    const addLiabilitiesAccount = () =>{
        if (liabilitiesInvisibleAccounts.length>0){
            props.toggleAccount(liabilitiesInvisibleAccounts[0].id, "true")
        }
    }

    const addEquityAccount = () =>{
        if (equityInvisibleAccounts.length>0){
            props.toggleAccount(equityInvisibleAccounts[0].id, "true")
        }
    }

    const allowRemoveLiablilitiesAccounts = ()=>{
        setRemoveLiabilitiesAccounts(removeLiabilitiesAccounts => !removeLiabilitiesAccounts)
        liabilitiesAccounts.map(account => 
            props.setMinimized(account.id, true)
        )
    }

    const allowRemoveEquityAccounts = ()=>{
        setRemoveEquityAccounts(removeEquityAccounts => !removeEquityAccounts)
        equityAccounts.map(account => 
            props.setMinimized(account.id, true)
        )
    }

    const halfView = parseFloat(props.transaction.number) > 5;
    const color = 'red';
    
    return(
        <div className = {`account-board liabilities ${color} ${(halfView?" halfView":"")}`}>
            <AccountLayoutTitle title = "Liabilities" addButton = {addLiabilitiesAccount} removeButton = {allowRemoveLiablilitiesAccounts} accounts={liabilitiesAccounts}/>
            <div className = "row account-place ">
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                        <Account id = "12" removable = {removeLiabilitiesAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                        <div className= 'row'>
                        <Account id = "13" removable = {removeLiabilitiesAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                        <Account id = "14" removable = {removeLiabilitiesAccounts}/>
                    </div>
                </div>
                <div className =  "col-3 account-col float-top">
                    <div className= 'row'>
                        <Account id = "15" removable = {removeLiabilitiesAccounts}/>
                    </div>
                </div>
            </div>
            <hr className = "liabilities-break"/>
            <div className = 'equity'>
                <AccountLayoutTitle title = "Equity" addButton = {addEquityAccount} removeButton = {allowRemoveEquityAccounts} accounts={equityAccounts}/>
                <div className = "row account-place ">
                    <div className =  "col-4 account-col float-top">
                        <div className= 'row'>
                            <Account id = "16" removable = {removeEquityAccounts}/>
                        </div>
                    </div>
                    <div className =  "col-4 account-col float-top">
                        <div className= 'row'>
                            <Account id = "17" removable = {removeEquityAccounts}/>
                        </div>
                    </div>
                    <div className =  "col-4 account-col float-top">
                        <div className= 'row'>
                            <Account id = "18" removable = {removeEquityAccounts}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}