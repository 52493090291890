import React from 'react';
import Sticker from '../Sticker'
import {Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import {toggleVisible, toggleMinimized, clearAccountStickers, restoreSidebarStickers, rerenderComponent, changeAccountName} from '../../Actions/wkshpActions';
import './account.scss';

function Account(props){
    const removeView = props.removable;
    const id = props.id;
    const color = props.color;
    const visible = props.visible;
    const stickers= props.stickers;
    const minimized = props.minimized;
    const currency  = props.currency;
    const total = parseInt(props.total).toLocaleString(); 
    const secondRow = (id==="2"|| id==="5"||id==="7"||id==="10")? "second-row":""

    const name = props.name

    let absoluteView = minimized?" ":"absolute-view"

    const removeAccount=() =>{
        let stickerIds = stickers.map(sticker => sticker.id)
        props.removeAccount(`account${id}`, stickerIds)
        props.nameChange(`account${id}`, "NAME")
        document.getElementById(`account${id}`).innerText='NAME'

        props.rerenderComponent(Date.now())
            
    }

    const moveCaretAtEnd=(e) =>{
        var temp_value = e.target.textContent
        e.target.textContent = ''
        e.target.textContent = temp_value
      }

    const extraRows = () =>{
        let extraRows = []
        if(stickers.length < 1){
            extraRows.push(<div className = 'row tower-spot '/>)
        }
        return extraRows
    }
    
    const toggleMinimized = () =>{
        absoluteView===" " ? absoluteView="absolute-view" : absoluteView=" "
        props.toggleMinimized("account"+id, !minimized)
    }
    const accountRows = () =>{ 
        const stickerRows = stickers.map(sticker => 
        <div className = 'row tower-spot '>
            <Sticker color = {sticker.color} id={sticker.id} index={0} text={sticker.amount} highlight = {sticker.highlighted} location = {sticker.location}/>
        </div>)
        return stickerRows
    }

    const nameChange = (e) =>{
        props.nameChange(`account${id}`, e.target.textContent)
    }
    
    return(
        <div>
            <Droppable droppableId={`account${id}`} isDropDisabled={minimized?true:false}>
            {(provided, snapshot) => (
            <div className = {`tower ${color} ${visible} ${absoluteView} ${secondRow}`}
            ref={provided.innerRef} {...provided.droppableProps}
                style={snapshot.isDraggingOver?{zIndex:0, display:'block'}:{}}>
                    {/* {provided.placeholder} */}
                <div className = 'card tower-body '>
                    <div className = 'row tower-title'>
                        <div contentEditable = "true" className= "text-box" id = {"account" +id} 
                        suppressContentEditableWarning={true} onFocus={moveCaretAtEnd} onInput= {nameChange}>
                            {name} </div>
                        {/* <input type = "text" defaultValue = "Title" className = "text-box"/> */}
                    </div>
                {!minimized &&
                    <div className = 'row tower-slots'>
                        {accountRows(snapshot)}
                        <div className = {`row tower-spot ${(snapshot.isDraggingOver? 'isDragging': "")}`}/>
                        {extraRows(snapshot).map(row=> row)}
                    </div>
                }
                </div>
                <div className = 'tower-base-container' onClick = {toggleMinimized}>
                    <label className="amountTotal">
                        {removeView &&
                            <svg width="1em" color = "white" height="1em" viewBox="0 0 16 16" className={"bi bi-trash "+ visible} fill="currentColor" xmlns="http://www.w3.org/2000/svg" 
                            onClick={removeAccount} /*className = {""+visible+""}*/>
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        }
                        {currency + total}</label>
                    <div className = 'tower-base-border'></div>
                    <div className = 'tower-base '></div>
                </div>
            </div>
            )}
            </Droppable	>
        </div>
    )
}

const mapStateToProps = (state, props) => 
	({
        color: state.accounts.filter((account)=> account.id === ("account"+props.id))[0].color,
        currency: state.currency,
        visible : state.accounts.filter((account)=> account.id === ("account"+props.id))[0].visible,
        stickers: state.accounts.filter((account)=> account.id === ("account"+props.id))[0].stickers,
        total: state.accounts.filter((account)=> account.id === ("account"+props.id))[0].total,
        minimized: state.accounts.filter((account)=> account.id === ("account"+props.id))[0].minimized,
        rerenderTrigger: state.rerenderTrigger,
        name: state.accounts.filter((account)=> account.id === ("account"+props.id))[0].name,
        id: props.id
    })
const mapDispatchToProps = dispatch => {
    return {
        removeAccount(accountId, stickerIds) {
            dispatch(
                toggleVisible(accountId, false)
            )
            dispatch(
                toggleMinimized(accountId, true)
            )
            dispatch(
                restoreSidebarStickers(stickerIds)
            )
            dispatch(
                clearAccountStickers(accountId)
            )
        },
        toggleMinimized(acccountId, minimized){
            dispatch(
                toggleMinimized(acccountId, minimized)
            )
        },
        rerenderComponent(timestamp){
            dispatch(
                rerenderComponent(timestamp)
            )
        },
        nameChange(accountId, name){
            dispatch(
                changeAccountName(accountId, name)
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
