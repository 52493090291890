import React, { useState }  from 'react';
import {Button} from 'reactstrap';
import {changeTransaction, toggleMinimized, toggleHighlighted, rerenderComponent, setSummaryView} from '../../Actions/wkshpActions';
import { connect } from 'react-redux'
import './navigationButton.scss';

function NavigationButton(props){

    const name = props.name
    const buttonName = name==='Next'? `${name} Transaction >` : `< ${name} Transaction`; 
    const accounts = props.accounts

    const currentTransactionId = props.currentTransaction.id;
    const currentTransactionIdNum = parseInt(currentTransactionId.substr(11))
    const newTransactionIdNum = name==='Previous'? currentTransactionIdNum-1 : name ==='Next'? currentTransactionIdNum+1 : currentTransactionIdNum;


    const newTransaction= props.transactions.filter(transaction => transaction.id === `transaction${newTransactionIdNum}`)[0]

    const onClick = ()=>{
        console.log(`new Transaction ${newTransactionIdNum}`)
        if (newTransactionIdNum!== 0 && newTransactionIdNum!==props.transactions.length+1){
            let newTransactionStickers = newTransaction.stickers
            console.log(currentTransactionIdNum)
            
            props.setSummaryView(false)

            if (newTransactionIdNum> 5){
                accounts.map(account => 
                    props.setMinimized(account.id, true)
                )
            }
            else{
                accounts.map(account => {
                    account.visible?props.setMinimized(account.id, false):props.setMinimized(account.id, true)
                }
                )
            }

            props.changeTransaction(currentTransactionId, `transaction${newTransactionIdNum}`, name)

            newTransactionStickers.map(sticker =>
                sticker.location!=='sidebar'? props.highlightStickers(sticker.location, sticker.id): sticker
            )
            props.updateTransaction();
        }
        else if (newTransactionIdNum===props.transactions.length+1 && name === 'Next'){
            console.log('setting summary view')
            props.setSummaryView(true)
        }
        
    }
    
    return(
        <div className = {`btn-navigation ${(props.name === "Previous"? 'left': 'right')}`}>
            <Button size = "sm" onClick = {onClick} id="navButton" className = "btn btn-link">{buttonName}</Button>
        </div>
    )
}

const mapStateToProps = (state, props) => 
	({
        currentTransaction : state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0],
        name: props.name,
        updateState: props.onClick,
        accounts: state.accounts,
        transactions: state.transactions,
        rerenderTrigger: state.rerenderTrigger,
        updateTransaction: props.onClick
	})
const mapDispatchToProps = dispatch => {
	return {
		changeTransaction(prevTransactionId, newTransactionId, direction) {
			dispatch(
				changeTransaction(prevTransactionId, newTransactionId, direction)
            )
            // dispatch(
            //     rerenderComponent(Date.now())
            // )
        },
        highlightStickers(accountId, stickerId){
            dispatch(
				toggleMinimized(accountId, false)
			) 
            dispatch(
				toggleHighlighted(accountId, stickerId, true)
			)
			setTimeout(()=>{
				dispatch(
					toggleHighlighted(accountId, stickerId, false)
				)
            },5000)           
        },
        setMinimized(acccountId, minimized){
            dispatch(
                toggleMinimized(acccountId, minimized)
            )
        },
        setSummaryView(summaryView){
            dispatch(
                setSummaryView(summaryView)
            )
        },
        rerenderComponent(timestamp){
            dispatch(
                rerenderComponent(timestamp)
            )
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButton)