import {combineReducers} from "redux";
import C from '../constants';
import {PURGE} from 'redux-persist'
import {authConstants}  from '../Actions/authActions'

const accountsList = (state = [], action) =>{
    if(
        action.type === C.TOGGLE_VISIBLE || 
        action.type === C.ADD_STICKER || 
        action.type === C.REMOVE_STICKER || 
        action.type === C.ADD_TOTAL||
        action.type === C.SUBTRACT_TOTAL ||
        action.type === C.TOGGLE_MINIMIZED||
        action.type === C.TOGGLE_HIGHTLIGHTED||
        action.type === C.CLEAR_ACCOUNT_STICKERS||
        action.type === C.RETURN_SIDEBAR_STICKER||
        action.type === C.CHANGE_ACCOUNT_NAME||
        action.type === C.RESET_ACCOUNTS){
        let accounts = [...state]
        let index = 0
        let account = null
        if(action.payload){
            index = accounts.findIndex(function(account, i){
                return account.id === action.payload.accountId
            })
            account =accounts.splice(index, 1)[0];
        }
        switch (action.type){
            case C.TOGGLE_VISIBLE:
                account.visible = action.payload.visible;
                break;
            case C.TOGGLE_MINIMIZED:
                account.minimized = action.payload.minimized;
                break;
            case C.TOGGLE_HIGHTLIGHTED:
                let stickerIndex = account.stickers.findIndex(sticker=>sticker.id === action.payload.stickerId);
                let sticker= account.stickers.splice(stickerIndex,1)[0]
                sticker.highlighted = action.payload.highlighted;
                if (stickerIndex === 0)
                    account.stickers = [sticker, ...account.stickers]
                else if (account.stickers.length === 1)
                    account.stickers = [account.stickers.slice()[0], sticker]
                else {
                    account.stickers = [...account.stickers.slice(0,stickerIndex), sticker, ...account.stickers.slice(stickerIndex)]
                }
                break;
            case C.ADD_STICKER:
                let draggingSticker = action.payload.sticker;
                draggingSticker.location = action.payload.accountId
                let newStickers = [...account.stickers, draggingSticker];
                account = {...account, stickers:newStickers}
                break;
            case C.REMOVE_STICKER:
                let stickers = state.filter(account=> account.id === action.payload.accountId)[0].stickers
                account.stickers = stickers.filter(sticker => sticker.id !== action.payload.stickerId);
                break;
            case C.CLEAR_ACCOUNT_STICKERS:
                account.stickers.map(sticker => {
                    sticker.visible = true
                    sticker.location = 'sidebar'
                })
                account.stickers = []
                account.total = 0
                break;
            case C.ADD_TOTAL:
                let stickerAmount = parseInt(account.stickers.filter(sticker=>sticker.id === action.payload.stickerId)[0].amount);
                let stickerColor = account.stickers.filter(sticker=>sticker.id === action.payload.stickerId)[0].color;
                if (account.color === stickerColor)
                    account.total = account.total + stickerAmount;
                else
                    account.total = account.total - stickerAmount;
                break;
            case C.SUBTRACT_TOTAL:
                let subtractStickerAmount = parseInt(account.stickers.filter(sticker=>sticker.id === action.payload.stickerId)[0].amount)
                let subtractStickerColor = account.stickers.filter(sticker=>sticker.id === action.payload.stickerId)[0].color;

                if (account.color === subtractStickerColor)
                    account.total = account.total - subtractStickerAmount;
                else
                    account.total = account.total + subtractStickerAmount;
                break;
            case C.RETURN_SIDEBAR_STICKER:
                let updatedStickers = account.stickers.filter(sticker=> sticker.id!== action.payload.stickerId);
                account = {...account, stickers:updatedStickers}
                break;
            case C.CHANGE_ACCOUNT_NAME:
                account = {...account, name:action.payload.name}
                break;
            case C.RESET_ACCOUNTS:
                accounts.forEach(account=>{
                    account.visible = false
                    account.stickers =[]
                    account.minimized = true
                    account.total = 0
                    account.name = 'NAME'
                }
                )
                return accounts
            case PURGE:
                return {}
            default:
                return state;
        }
        let newAccounts = [...accounts.slice(0,index), account, ...accounts.slice(index)]
        return newAccounts
    }
    else{
     return state
    }

}

const transactionsList = (state =[], action)=>{
    if (action.type === C.CHANGE_TRANSACTION){
        if(action.payload.newTransactionId === ("transaction0") || action.payload.newTransactionId === (`transaction${state.length + 1}`))
            return state
        let transactions = [...state]

        if(action.payload.direction === "Previous"){
            let index = transactions.findIndex(function(transaction, i){
                return transaction.id === action.payload.newTransactionId
            })
            let editTransactions = transactions.splice(index, 2)

            editTransactions[0].currentTransaction = true;
            editTransactions[1].currentTransaction = false;


            return [...transactions.slice(0,index), editTransactions[0], editTransactions[1], ...transactions.slice(index)]
        }
        else if (action.payload.direction === "Next") {
            let index = transactions.findIndex(transaction=>transaction.id === action.payload.prevTransactionId)
            let editTransactions = transactions.splice(index, 2)

            editTransactions[0].currentTransaction = false;
            editTransactions[1].currentTransaction = true;


            return [...transactions.slice(0,index), editTransactions[0], editTransactions[1], ...transactions.slice(index)]
        }
        else
            return transactions
        // else if (action.payload.direction === "Last"){

        // }
    }
    else if (action.type === C.REMOVE_SIDEBAR_STICKER){
        let transactions = [...state]

        let index = transactions.findIndex(transaction=> transaction.id === action.payload.transactionId)
        let transaction = transactions.splice(index, 1)[0]

        let stickerIndex = transaction.stickers.findIndex(sticker => sticker.id === action.payload.stickerId);
        let sticker= transaction.stickers.splice(stickerIndex,1)[0]
        sticker.visible = false;
        if (stickerIndex === 0)
            transaction.stickers = [sticker, transaction.stickers.slice(0)[0]]
        else
            transaction.stickers = [transaction.stickers.slice(0)[0], sticker]
        
        return [...transactions.slice(0,index), transaction, ...transactions.slice(index)]
    }
    else if (action.type === C.RESTORE_SIDEBAR_STICKERS){
        let transactions = [...state]
        let transactionNums = action.payload.stickerIds.map(stickerId => stickerId.split('_')[1])
        transactions.map(transaction =>{
            if (transactionNums.includes(transaction.number)){
                transaction.stickers.map(sticker => action.payload.stickerIds.includes(sticker.id)?sticker.visible = true : sticker)
            }
        })
        return transactions

    }
    else if (action.type === C.RETURN_SIDEBAR_STICKER){
        let transactions =[...state]
        let transaction = transactions.filter(transaction => transaction.id === action.payload.transactionId)[0]
        let sticker = transaction.stickers.filter(sticker =>
            sticker.id.split("_")[0] === action.payload.stickerColor
        )[0]
        sticker.location = 'sidebar'
        sticker.visible = true
        return transactions

    }
    else if (action.type === C.RESET_ACCOUNTS){
        let transactions =[...state]
        transactions.forEach(transaction=>{
            transaction.id === 'transaction1'? transaction.currentTransaction = true: transaction.currentTransaction = false
            transaction.stickers.forEach(sticker=>{
                sticker.visible = true
                sticker.location = 'sidebar'
            })
        })
        return transactions
    }
    else if(action.type === C.SAVE_NOTE){
        console.log(`saving note ${action.payload.note}`)
        let transactions =[...state]
        let index = transactions.findIndex(transaction=> transaction.id === action.payload.transactionId)
        let transaction = transactions.splice(index, 1)[0]
        console.log(JSON.stringify(transaction))

        transaction.note = action.payload.note;
        return [...transactions.slice(0,index), transaction, ...transactions.slice(index)]
    }
    else{
        return state
    }
}

const currency = (state = "", action) =>{
    if (action.type === C.SET_CURRENCY){
        return state = action.payload;
    }
    return state;
}
const rerender = (state = "", action) =>{
    if (action.type === C.TRIGGER_RERENDER){
        return state = action.payload;
    }
    return state;
}

const companyName = (state = "", action) =>{
    if (action.type === C.SET_COMPANY_NAME){
        return state = action.payload;
    }
    return state;
}

const summaryView = (state = "", action) =>{
    if (action.type === C.SET_SUMMARY_VIEW){
        console.log(`summary view ${action.payload}`)
        return state = action.payload;
    }
    return state;
}

const purge = (state = {}, action) =>{
    if (action.type === PURGE)
    return {}
}

const auth = (
    state = {
      isLoggingIn: false,
      isLoggingOut: false,
      isVerifying: false,
      loginError: false,
      logoutError: false,
      isAuthenticated: false,
      user: {}
    },
    action
  ) => {
    switch (action.type) {
      case authConstants.LOGIN_REQUEST:
        return {
          ...state,
          isLoggingIn: true,
          loginError: false
        };
      case authConstants.LOGIN_SUCCESS:
        return {
          ...state,
          isLoggingIn: false,
          isAuthenticated: true,
          user: action.user
        };
      case authConstants.LOGIN_FAILURE:
        return {
          ...state,
          isLoggingIn: false,
          isAuthenticated: false,
          loginError: true
        };
      case authConstants.LOGOUT_REQUEST:
        return {
          ...state,
          isLoggingOut: true,
          logoutError: false
        };
      case authConstants.LOGOUT_SUCCESS:
          console.log('logging out')
        return {
          ...state,
          isLoggingOut: false,
          isAuthenticated: false,
          user: {}
        };
      case authConstants.LOGOUT_FAILURE:
        return {
          ...state,
          isLoggingOut: false,
          logoutError: true
        };
      case authConstants.VERIFY_REQUEST:
        return {
          ...state,
          isVerifying: true,
          verifyingError: false
        };
      case authConstants.VERIFY_SUCCESS:
        return {
          ...state,
          isVerifying: false
        };
      default:
        return state;
    }
  };

export default combineReducers({
    accounts: accountsList,
    transactions: transactionsList,
    currency:currency,
    rerenderTrigger: rerender,
    companyName: companyName,
    summaryView: summaryView,
    auth: auth
  })