import React, {useState} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import Sticker from '../Sticker';
import DisabledSticker from '../Sticker/DisabledSticker'
import ResetButton from '../ResetButton'
import LogoutButton from '../LogoutButton'
import NoteModal from '../Note'
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import './sidebar.scss';

function Sidebar (props){
    const [noteModalOpen, setNoteModalOpen] = useState(false)

    const stickers =props.stickers
    const currentTransaction = props.transaction
    const userName = props.userName

    const sidebarStickers = stickers.map((sticker)=>
        <div className = 'row col sidebar-sticker'>
        {sticker.visible ? 
        <Sticker color = {sticker.color} id ={sticker.id} index = {1} text = {sticker.amount} highlight = {sticker.highlighted} location = {sticker.location}/> : 
        <DisabledSticker color = {sticker.color} text= {sticker.amount}/>}
        </div>
    )

    const toggleNoteModal = (openModal) =>{
        setNoteModalOpen(openModal)
    }

    return(
        <Droppable droppableId="SIDEBAR">
                {provided => (
        <div className = "rct-sidebar col-2" ref={provided.innerRef}>
            {/* {provided.placeholder} */}
            <div className = 'row col sidebar-logo'>
                <img src = {process.env.PUBLIC_URL+'/ame-logo.png'}/>
            </div>
            {sidebarStickers}
            {/* <div className = 'row col sidebar-sticker'>
                <Sticker color = 'red' id ={redStickerId} index = {1} text = {currentTransaction.stickerAmount}/>
            </div>
            <div className = 'row col sidebar-sticker'>
                <Sticker color = 'blue' id ={blueStickerId} index = {2} text = {currentTransaction.stickerAmount}/>
            </div> */}
            <hr className = 'stickers-break'/>
            <div className = 'row col sidebar-title'>
                Transaction #{currentTransaction.number}
            </div>
            <div className = 'row col transaction'>
                <div className = 'row col-12 date'>
                {`${currentTransaction.date}:`}
                </div>
                <div className = 'row col-12 summary'>
                {currentTransaction.summary}
                </div>
            </div>
            <div className = 'note row'>
                <Button onClick = {()=>toggleNoteModal(true)} className = 'btn btn-sm'>Add Note</Button>
                <NoteModal open = {noteModalOpen} toggleOpen = {toggleNoteModal}/>
            </div>
            <hr className = 'transaction-break'/>
            <div className = 'reset-logout row'>
                <ResetButton updateTransaction= {props.updateTransaction}/>
                <LogoutButton/>
            </div>
            <div className = 'row col user-name'>
                {`User: ${userName}`}
            </div>

        </div>
    )}
    </Droppable>
    )
}

const mapStateToProps = (state, props) => 
	({
        stickers: state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0].stickers,
        transaction: state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0],
        rerenderTrigger: state.rerenderTrigger,
        userName : state.auth.user.name,
        updateTransaction: props.updateTransaction
    })
    
export default connect(mapStateToProps)(Sidebar)