import C from '../constants';
import {PURGE} from 'redux-persist'

export const addStickerfromSidebar = (accountId, transactionId, stickerId)=>{
    return (dispatch, getState) =>{
        const sticker = getState().transactions.filter(transaction => transaction.id === transactionId)[0].stickers.filter(sticker=>sticker.id===stickerId)[0];
        console.log(`sticker ${JSON.stringify(sticker)} ${JSON.stringify(getState().transactions.filter(transaction => transaction.id === transactionId)[0].stickers)}`)
        dispatch(addSticker(accountId, stickerId, sticker))
    }
}
export const addStickerfromAccount = (sourceAccountId, destAccountId, stickerId)=>{
    return (dispatch, getState) =>{
        const sticker = getState().accounts.filter(account=>account.id === sourceAccountId)[0].stickers.filter(sticker=>sticker.id===stickerId)[0];
        dispatch(addSticker(destAccountId, stickerId, sticker))
    }
}
export const addSticker =(accountId,  id, sticker) =>
    ({
        type: C.ADD_STICKER,
        payload: {accountId, id, sticker}
    });

export const toggleVisible = (accountId, visible) =>
    ({
        type:C.TOGGLE_VISIBLE,
        payload: {visible, accountId}
    })

export const toggleMinimized = (accountId, minimized) =>
    ({
        type:C.TOGGLE_MINIMIZED,
        payload: {minimized, accountId}
    })

export const removeSticker =(accountId, stickerId) =>
    ({
        type: C.REMOVE_STICKER,
        payload: {accountId, stickerId}
    });

export const removeSidebarSticker = (stickerId, transactionId) =>
    ({
        type: C.REMOVE_SIDEBAR_STICKER,
        payload: {stickerId, transactionId}
    })
export const addTotal = (accountId, stickerId)=>
    ({
        type: C.ADD_TOTAL,
        payload: {accountId, stickerId}
    })
export const subtractTotal = (accountId, stickerId)=>
    ({
        type: C.SUBTRACT_TOTAL,
        payload: {accountId, stickerId}
    })

export const changeTransaction = (prevTransactionId, newTransactionId, direction)=>
    ({
        type: C.CHANGE_TRANSACTION,
        payload: {prevTransactionId, newTransactionId, direction}
    })

export const setCurrency = (currencySymbol)=>
    ({
        type: C.SET_CURRENCY,
        payload: currencySymbol
    })

export const toggleHighlighted = (accountId, stickerId, highlighted) =>
    ({ 
        type: C.TOGGLE_HIGHTLIGHTED, 
        payload: {accountId, stickerId, highlighted}
    })

export const clearAccountStickers=(accountId)=>
    ({ 
        type: C.CLEAR_ACCOUNT_STICKERS, 
        payload: {accountId}
    })

export const changeAccountName=(accountId, name)=>
    ({ 
        type: C.CHANGE_ACCOUNT_NAME, 
        payload: {accountId, name}
    })

export const restoreSidebarStickers = (stickerIds) =>
    ({ 
        type: C.RESTORE_SIDEBAR_STICKERS, 
        payload: {stickerIds}
    })

export const returnSidebarSticker = (transactionId, stickerColor, accountId, stickerId) =>
    ({ 
        type: C.RETURN_SIDEBAR_STICKER, 
        payload: {transactionId, stickerColor, accountId, stickerId}
    })
export const rerenderComponent = (timestamp)=>
    ({
        type: C.TRIGGER_RERENDER,
        payload: timestamp
    })

export const setCompanyName = (name)=>
    ({
        type: C.SET_COMPANY_NAME,
        payload: name
    })
export const purgeState = ()=>
    ({
        type: PURGE,
        key: "root",
        result: () => null
    })
export const resetAccounts = ()=>
    ({
        type: C.RESET_ACCOUNTS
    })

export const saveNote = (transactionId, note)=>
    ({
        type: C.SAVE_NOTE,
        payload: {transactionId, note}
    })

export const setSummaryView = (summaryView)=>
    ({
        type: C.SET_SUMMARY_VIEW,
        payload: summaryView
    })


