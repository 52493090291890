import React, {useState} from 'react';
import '../board.scss';
import Account from '../../Account';
import AccountLayoutTitle from './AccountLayoutTitle';

export default function IncomeAccountLayout(props){

    const [removeAccounts, setRemoveAccounts] = useState(false)

    const visible = props.visible
    const incomeAccounts = props.accounts.slice(19)
    const invisibleAccounts = incomeAccounts.filter(account=> !account.visible)

    let incomeTotal = 0;
    let expensesTotal = 0;

    props.accounts.slice(19).map(account=>{
        incomeTotal = incomeTotal+ account.total
    })

    props.accounts.slice(6,12).map(account=>{
        expensesTotal = expensesTotal+ account.total
    })

    let profit  = (incomeTotal - expensesTotal) > 0;

    const addAccount = () =>{
        if (invisibleAccounts.length>0){
            props.toggleAccount(invisibleAccounts[0].id, "true")
        }
    }

    const allowRemoveAccounts = ()=>{
        setRemoveAccounts(removeAccounts => !removeAccounts)
        incomeAccounts.map(account => 
            props.setMinimized(account.id, true)
        )
    }

    const color = 'red';
    return(
        <div className = {`account-board income ${visible} ${color} ${(profit ? " extend" : "")}`}>
            <AccountLayoutTitle title = "Income" addButton = {addAccount} 
            removeButton = {allowRemoveAccounts} accounts={incomeAccounts}/>
                <div className = "row col-12 account-place ">
                    <div className =  "col-4 account-col">
                        <div className= 'row'>
                            <Account id = "19" removable = {removeAccounts} />
                        </div>
                    </div>
                    <div className =  "col-4 account-col">
                        <div className= 'row'>
                            <Account id = "20" removable = {removeAccounts} />
                        </div>
                    </div>
                    <div className =  "col-4 account-col">
                        <div className= 'row'>
                            <Account id = "21" removable = {removeAccounts} />
                        </div>
                    </div>
                </div>
                {(profit) &&
                <div className = 'total-calculation'>
                    <div className = "row col-12">
                        <hr className="total-break"/>
                        <label className='totals-title'>Profit</label>
                        {/* <label className = 'totals-amt'>{profit}</label> */}
                    </div>
                </div>
                }
        </div>
    )
}