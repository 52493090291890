import React, { useState, useEffect }  from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import {connect} from 'react-redux';
import {saveNote, rerenderComponent} from '../../Actions/wkshpActions';
import './note.scss'

function NoteModal(props){

    const [open, setOpen] = useState(props.open)
    const [note, setNote] = useState(props.currentTransaction.note)

    useEffect(() => {
        setOpen(props.open)
        setNote(props.currentTransaction.note)
    }, [props.open, props.currentTransaction.note]);

    const onClick = () =>{
        props.saveNote(props.currentTransaction.id, note)
        props.toggleOpen(false)
    }
    console.log(`note ${note}`)
    const onNoteChange = (e) =>{
        setNote(e)
    }

    return(
        <div>
            <Modal className = "note-modal" isOpen={open} >
                <ModalHeader>Notes</ModalHeader>
                <ModalBody className = "note-modal-body">
                    <div className = "row col-12 note-row">
                        {console.log(`changing note ${note}`)}
                        <Input type='textarea' value={note} onChange={(e) => onNoteChange(`${e.target.value}`)}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className = "btn-light" onClick={onClick}>Save</Button>
                </ModalFooter>
            </Modal>
      </div>
    )
}

const mapStateToProps = (state, props) => 
	({
        currentTransaction : state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0],
        open: props.open,
        toggleOpen: props.toggleOpen
	})

const mapDispatchToProps = dispatch => {
	return {
		saveNote(transactionId, note) {
			dispatch(
				saveNote(transactionId, note)
			)
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal)