import AssetsAccountLayout from "./AssetsAccountLayout";
import LiabilitiesAccountLayout from "./LiabilitiesAccountLayout";
import ExpensesAccountLayout from "./ExpensesAccountLayout";
import IncomeAccountLayout from "./IncomeAccountLayout";
import {connect} from 'react-redux';
import {toggleVisible, toggleMinimized} from '../../../Actions/wkshpActions';

const mapStateToProps = (state, props) => 
	({
		accounts: state.accounts,
		transaction: state.transactions.filter((transaction)=>transaction.currentTransaction===true)[0]
	})

const mapDispatchToProps = dispatch => {
	return {
		toggleAccount(accountId, visible) {
			dispatch(
				toggleVisible(accountId, visible)
			)
			dispatch(
				toggleMinimized(accountId, false)
			)
		},
		setMinimized(accountId, minimized){
			dispatch(
				toggleMinimized(accountId, minimized)
			)
		}
	}
}

export const AssetsLayout = connect(mapStateToProps, mapDispatchToProps)(AssetsAccountLayout)
export const LiabilitiesLayout = connect(mapStateToProps, mapDispatchToProps)(LiabilitiesAccountLayout)
export const ExpensesLayout = connect(mapStateToProps, mapDispatchToProps)(ExpensesAccountLayout)
export const IncomeLayout = connect(mapStateToProps, mapDispatchToProps)(IncomeAccountLayout)

