import React, { useState }  from 'react';
import {Button, Tooltip} from 'reactstrap';
import './accountButton.scss';



export default function AccountButton(props){
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle =() =>{
        setTooltipOpen((tooltipOpen)=>(!tooltipOpen));
      }
    
    return(
        <div className = 'btn-add-account'>
            <Button size = "sm" onClick = {props.onClick} id="accountButton" className = "btn-light">+</Button>
            <Tooltip placement="right" isOpen={tooltipOpen} target="accountButton" toggle={toggle}>
          Add Account
        </Tooltip>
        </div>
    )
}